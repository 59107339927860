import * as React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { Link } from 'gatsby'

import { heights, dimensions, colors } from '../styles/variables'
import Container from './Container'

const StyledHeader = styled.header`
  height: ${heights.header}px;
  padding: 0 ${dimensions.containerPadding}rem;
  background-color: ${colors.brand};
  color: ${transparentize(0.5, colors.white)};
`

const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`

const HomepageLink = styled(Link)`
  color: ${colors.white};
  font-size: 1.5rem;
  font-weight: 600;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

interface HeaderProps {
  title: string
}

const Header: React.SFC<HeaderProps> = ({ title }) => (
	<div className="elementor elementor-117 elementor-type-header elementor-location-header">
		<div className="elementor-inner">
			<div className="elementor-section-wrap">
				<section
					className="has_ae_slider elementor-element elementor-element-3ba1b426 elementor-section-content-middle elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
					data-id="3ba1b426" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"
					data-element_type="section">
					<div className="elementor-container elementor-column-gap-no">
						<div className="elementor-row">
							<div
								className="has_ae_slider elementor-element elementor-element-41745698 elementor-column elementor-col-33 elementor-top-column"
								data-id="41745698" data-element_type="column">
								<div className="elementor-column-wrap elementor-element-populated">
									<div className="elementor-widget-wrap">
										<div data-id="a36dd18"
											 className="elementor-element elementor-element-a36dd18 elementor-nav-menu--dropdown-mobile elementor-nav-menu__align-center elementor-nav-menu__text-align-center elementor-nav-menu--indicator-classic elementor-widget elementor-widget-nav-menu"
											 data-settings="{&quot;layout&quot;:&quot;horizontal&quot;}"
											 data-element_type="nav-menu.default">
											<div className="elementor-widget-container">
												<nav
													className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade">
													<ul id="menu-1-a36dd18" className="elementor-nav-menu"
														data-smartmenus-id="15370504414125137">
														<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-38">
															<a target="_blank"
															   href="http://investors.middlesexwater.com/"
															   className="elementor-item">Investors</a></li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-226">
															<a href="https://www.middlesexwater.com/developers-partners/"
															   className="elementor-item">Developers &amp; Partners</a>
														</li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-223">
															<a href="https://www.middlesexwater.com/contact-us/"
															   className="elementor-item">Contact Us</a></li>
													</ul>
												</nav>
												<div className="elementor-menu-toggle">
													<i className="eicon" aria-hidden="true" />
												</div>
												<nav
													className="elementor-nav-menu--dropdown elementor-nav-menu__container">
													<ul id="menu-2-a36dd18" className="elementor-nav-menu"
														data-smartmenus-id="153705044141397">
														<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-38">
															<a target="_blank"
															   href="http://investors.middlesexwater.com/"
															   className="elementor-item">Investors</a></li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-226">
															<a href="https://www.middlesexwater.com/developers-partners/"
															   className="elementor-item">Developers &amp; Partners</a>
														</li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-223">
															<a href="https://www.middlesexwater.com/contact-us/"
															   className="elementor-item">Contact Us</a></li>
													</ul>
												</nav>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="has_ae_slider elementor-element elementor-element-a3ccb5a elementor-column elementor-col-33 elementor-top-column"
								data-id="a3ccb5a" data-element_type="column">
								<div className="elementor-column-wrap elementor-element-populated">
									<div className="elementor-widget-wrap">
										<div data-id="6cb92c6"
											 className="elementor-element elementor-element-6cb92c6 elementor-search-form--skin-minimal elementor-widget elementor-widget-search-form"
											 data-settings="{&quot;skin&quot;:&quot;minimal&quot;}"
											 data-element_type="search-form.default">
											<div className="elementor-widget-container">
												<form className="elementor-search-form" role="search"
													  action="https://www.middlesexwater.com" method="get">
													<div className="elementor-search-form__container">
														<div className="elementor-search-form__icon">
															<i className="fa fa-search" aria-hidden="true" />
														</div>
														<input placeholder="Search..."
															   className="elementor-search-form__input" type="search"
															   name="s" title="Search"
														/>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="has_ae_slider elementor-element elementor-element-7531fe1a elementor-column elementor-col-33 elementor-top-column"
								data-id="7531fe1a" data-element_type="column">
								<div className="elementor-column-wrap elementor-element-populated">
									<div className="elementor-widget-wrap">
										<div data-id="dfcdb72"
											 className="elementor-element elementor-element-dfcdb72 elementor-hidden-desktop elementor-hidden-tablet elementor-widget elementor-widget-heading"
											 data-element_type="heading.default">
											<div className="elementor-widget-container">
												<h4 className="elementor-heading-title elementor-size-default">Our
													Companies</h4></div>
										</div>
										<div data-id="b166ec5"
											 className="elementor-element elementor-element-b166ec5 elementor-nav-menu__align-right elementor-nav-menu--dropdown-mobile elementor-nav-menu__text-align-center elementor-nav-menu--indicator-chevron elementor-nav-menu--stretch elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
											 data-settings="{&quot;full_width&quot;:&quot;stretch&quot;,&quot;layout&quot;:&quot;horizontal&quot;,&quot;toggle&quot;:&quot;burger&quot;}"
											 data-element_type="nav-menu.default">
											<div className="elementor-widget-container">
												<nav
													className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade">
													<ul id="menu-1-b166ec5" className="elementor-nav-menu"
														data-smartmenus-id="15370504414153477">
														<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-124">
															<a className="elementor-item has-submenu"
															   id="sm-15370504414153477-1" aria-haspopup="true"
															   aria-controls="sm-15370504414153477-2"
															   aria-expanded="false">Our Companies<span
																className="sub-arrow"><i className="fa"/></span></a>
															<ul className="sub-menu elementor-nav-menu--dropdown"
																id="sm-15370504414153477-2" role="group"
																aria-hidden="true"
																aria-labelledby="sm-15370504414153477-1"
																aria-expanded="false">
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-413">
																	<a href="https://www.middlesexwater.com/middlesex-water-company/"
																	   className="elementor-sub-item">Middlesex Water
																		Company</a></li>
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-72">
																	<a href="https://www.middlesexwater.com/tidewater-utilities/"
																	   className="elementor-sub-item">Tidewater
																		Utilities, Inc.</a></li>
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-69">
																	<a href="https://www.middlesexwater.com/pinelands-water-and-wastewater-company/"
																	   className="elementor-sub-item">Pinelands Water
																		and Wastewater Company</a></li>
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-67">
																	<a href="https://www.middlesexwater.com/utility-service-affiliates-perth-amboy-inc/"
																	   className="elementor-sub-item">Utility Service
																		Affiliates (Perth Amboy), Inc.</a></li>
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-68">
																	<a href="https://www.middlesexwater.com/utility-service-affiliates-avalon/"
																	   className="elementor-sub-item">Utility Service
																		Affiliates (Avalon)</a></li>
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-65">
																	<a href="https://www.middlesexwater.com/twin-lakes-utilities-inc/"
																	   className="elementor-sub-item">Twin Lakes
																		Utilities, Inc.</a></li>
															</ul>
														</li>
													</ul>
												</nav>
												<div className="elementor-menu-toggle">
													<i className="eicon" aria-hidden="true" />
												</div>
												<nav
													className="elementor-nav-menu--dropdown elementor-nav-menu__container"
													style={{top: '60px', width: ' 1030px', left: '0'}}>
													<ul id="menu-2-b166ec5" className="elementor-nav-menu"
														data-smartmenus-id="15370504414175535">
														<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-124">
															<a className="elementor-item has-submenu"
															   id="sm-15370504414175535-1" aria-haspopup="true"
															   aria-controls="sm-15370504414175535-2"
															   aria-expanded="false">Our Companies<span
																className="sub-arrow"><i className="fa"></i></span></a>
															<ul className="sub-menu elementor-nav-menu--dropdown"
																id="sm-15370504414175535-2" role="group"
																aria-hidden="true"
																aria-labelledby="sm-15370504414175535-1"
																aria-expanded="false">
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-413">
																	<a href="https://www.middlesexwater.com/middlesex-water-company/"
																	   className="elementor-sub-item">Middlesex Water
																		Company</a></li>
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-72">
																	<a href="https://www.middlesexwater.com/tidewater-utilities/"
																	   className="elementor-sub-item">Tidewater
																		Utilities, Inc.</a></li>
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-69">
																	<a href="https://www.middlesexwater.com/pinelands-water-and-wastewater-company/"
																	   className="elementor-sub-item">Pinelands Water
																		and Wastewater Company</a></li>
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-67">
																	<a href="https://www.middlesexwater.com/utility-service-affiliates-perth-amboy-inc/"
																	   className="elementor-sub-item">Utility Service
																		Affiliates (Perth Amboy), Inc.</a></li>
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-68">
																	<a href="https://www.middlesexwater.com/utility-service-affiliates-avalon/"
																	   className="elementor-sub-item">Utility Service
																		Affiliates (Avalon)</a></li>
																<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-65">
																	<a href="https://www.middlesexwater.com/twin-lakes-utilities-inc/"
																	   className="elementor-sub-item">Twin Lakes
																		Utilities, Inc.</a></li>
															</ul>
														</li>
													</ul>
												</nav>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section
					className="has_ae_slider elementor-element elementor-element-6dfc5493 elementor-section-content-middle elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
					data-id="6dfc5493" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"
					data-element_type="section">
					<div className="elementor-container elementor-column-gap-no">
						<div className="elementor-row">
							<div
								className="has_ae_slider elementor-element elementor-element-6a80510b elementor-column elementor-col-33 elementor-top-column"
								data-id="6a80510b" data-element_type="column">
								<div className="elementor-column-wrap elementor-element-populated">
									<div className="elementor-widget-wrap">
										<div data-id="2120ea08"
											 className="elementor-element elementor-element-2120ea08 elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
											 data-element_type="theme-site-logo.default">
											<div className="elementor-widget-container">
												<div className="elementor-image">
													<a href="https://www.middlesexwater.com" data-elementor-open-lightbox="">
														<img width="1461" height="662"
															 src="https://www.middlesexwater.com/wp-content/uploads/2018/06/MWC_Blue.png"
															 className="attachment-full size-full"
															 alt="Middlesex Water Company"
															 srcSet="https://www.middlesexwater.com/wp-content/uploads/2018/06/MWC_Blue.png 1461w, https://www.middlesexwater.com/wp-content/uploads/2018/06/MWC_Blue-680x308.png 680w"
															 sizes="(max-width: 1461px) 100vw, 1461px"/>
                                                    </a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="has_ae_slider elementor-element elementor-element-6d8633f8 elementor-column elementor-col-33 elementor-top-column"
								data-id="6d8633f8" data-element_type="column">
								<div className="elementor-column-wrap elementor-element-populated">
									<div className="elementor-widget-wrap">
										<div data-id="6c5023a9"
											 className="elementor-element elementor-element-6c5023a9 elementor-nav-menu__align-center elementor-nav-menu--stretch elementor-nav-menu__text-align-center elementor-nav-menu--indicator-classic elementor-nav-menu--dropdown-tablet elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
											 data-settings="{&quot;full_width&quot;:&quot;stretch&quot;,&quot;layout&quot;:&quot;horizontal&quot;,&quot;toggle&quot;:&quot;burger&quot;}"
											 data-element_type="nav-menu.default">
											<div className="elementor-widget-container">
												<nav
													className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade">
													<ul id="menu-1-6c5023a9" className="elementor-nav-menu"
														data-smartmenus-id="1537050441419814">
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-32">
															<a href="https://www.middlesexwater.com/about-us/"
															   className="elementor-item">About Us</a></li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-232">
															<a href="https://www.middlesexwater.com/tips-tools/"
															   className="elementor-item">Tips &amp; Tools</a></li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-36">
															<a href="https://www.middlesexwater.com/news-room/"
															   className="elementor-item">News Room</a></li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-34">
															<a href="https://www.middlesexwater.com/careers/"
															   className="elementor-item">Careers</a></li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-231">
															<a href="https://www.middlesexwater.com/alerts/"
															   className="elementor-item">Alerts</a></li>
													</ul>
												</nav>
												<div className="elementor-menu-toggle">
													<i className="eicon" aria-hidden="true" />
												</div>
												<nav
													className="elementor-nav-menu--dropdown elementor-nav-menu__container"
													style={{top: '64px', width: ' 1030px', left: '0'}}>
													<ul id="menu-2-6c5023a9" className="elementor-nav-menu"
														data-smartmenus-id="15370504414191338">
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-32">
															<a href="https://www.middlesexwater.com/about-us/"
															   className="elementor-item">About Us</a></li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-232">
															<a href="https://www.middlesexwater.com/tips-tools/"
															   className="elementor-item">Tips &amp; Tools</a></li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-36">
															<a href="https://www.middlesexwater.com/news-room/"
															   className="elementor-item">News Room</a></li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-34">
															<a href="https://www.middlesexwater.com/careers/"
															   className="elementor-item">Careers</a></li>
														<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-231">
															<a href="https://www.middlesexwater.com/alerts/"
															   className="elementor-item">Alerts</a></li>
													</ul>
												</nav>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="has_ae_slider elementor-element elementor-element-b85bb66 elementor-column elementor-col-33 elementor-top-column"
								data-id="b85bb66" data-element_type="column">
								<div className="elementor-column-wrap elementor-element-populated">
									<div className="elementor-widget-wrap">
										<div data-id="dcf58ed"
											 className="elementor-element elementor-element-dcf58ed elementor-align-center elementor-widget elementor-widget-global elementor-global-269 elementor-widget-button"
											 data-element_type="button.default">
											<div className="elementor-widget-container">
												<div className="elementor-button-wrapper">
													<a href="https://www.middlesexwater.com/customer-care/payment-options/"
													   className="elementor-button-link elementor-button elementor-size-sm"
													   target="_blank" role="button">
						<span className="elementor-button-content-wrapper">
						<span className="elementor-button-text">PAYMENT OPTIONS</span>
		</span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
)

export default Header
