import * as React from 'react';

export const StyledFooter = (props) => (
	<div className="site-footer  ">
		<div className="elementor elementor-137 elementor-type-footer elementor-location-footer">
			<div className="elementor-inner">
				<div className="elementor-section-wrap">
					<section
						className="has_ae_slider elementor-element elementor-element-76e9846 elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
						data-id="76e9846"
						data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shape_divider_bottom&quot;:&quot;waves&quot;}"
						data-element_type="section">
						<div className="elementor-shape elementor-shape-bottom" data-negative="false">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100"
								 preserveAspectRatio="none">
								<path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"/>
							</svg>
						</div>
						<div className="elementor-container elementor-column-gap-default">
							<div className="elementor-row">
								<div
									className="has_ae_slider elementor-element elementor-element-ebbea00 elementor-column elementor-col-100 elementor-top-column"
									data-id="ebbea00" data-element_type="column">
									<div className="elementor-column-wrap elementor-element-populated">
										<div className="elementor-widget-wrap">
											<section
												className="has_ae_slider elementor-element elementor-element-e1abcee elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
												data-id="e1abcee" data-element_type="section">
												<div className="elementor-container elementor-column-gap-default">
													<div className="elementor-row">
														<div
															className="has_ae_slider elementor-element elementor-element-340d708 elementor-column elementor-col-20 elementor-inner-column"
															data-id="340d708" data-element_type="column">
															<div
																className="elementor-column-wrap elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div data-id="215bcc6"
																		 className="elementor-element elementor-element-215bcc6 elementor-widget elementor-widget-image"
																		 data-element_type="image.default">
																		<div className="elementor-widget-container">
																			<div className="elementor-image">
																				<img width="855" height="388"
																					 src="https://www.middlesexwater.com/wp-content/uploads/2018/06/MWC_Wht.png"
																					 className="attachment-large size-large"
																					 alt="Middlesex Water Company"
																					 srcSet="https://www.middlesexwater.com/wp-content/uploads/2018/06/MWC_Wht.png 1006w, https://www.middlesexwater.com/wp-content/uploads/2018/06/MWC_Wht-680x308.png 680w"
																					 sizes="(max-width: 855px) 100vw, 855px"/>
																			</div>
																		</div>
																	</div>
																	<div data-id="19d6773a"
																		 className="elementor-element elementor-element-19d6773a elementor-widget elementor-widget-text-editor"
																		 data-element_type="text-editor.default">
																		<div className="elementor-widget-container">
																			<div
																				className="elementor-text-editor elementor-clearfix">
																				<p>Middlesex Water Company<br/>1500
																					Ronson Road<br/>Iselin, NJ 08830
																				</p></div>
																		</div>
																	</div>
																	<div data-id="c789464"
																		 className="elementor-element elementor-element-c789464 elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<h2 className="elementor-heading-title elementor-size-default">
																				<a href="https://www.middlesexwater.com/contact-us/">Contact
																					Us &gt;</a></h2></div>
																	</div>
																	<div data-id="f50a1ce"
																		 className="elementor-element elementor-element-f50a1ce elementor-shape-rounded elementor-widget elementor-widget-global elementor-global-1840 elementor-widget-social-icons"
																		 data-element_type="social-icons.default">
																		<div className="elementor-widget-container">
																			<div
																				className="elementor-social-icons-wrapper">
																				<a className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-animation-grow"
																				   href="https://www.facebook.com/MIDDLESEXWATER/"
																				   target="_blank">
																					<span
																						className="elementor-screen-only">Facebook</span>
																					<i className="fa fa-facebook"/>
																				</a>
																				<a className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-grow"
																				   href="https://twitter.com/MiddlesexWater"
																				   target="_blank">
																					<span
																						className="elementor-screen-only">Twitter</span>
																					<i className="fa fa-twitter"/>
																				</a>
																				<a className="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-animation-grow"
																				   href="https://linkedin.com/company/Middlesex-Water-company"
																				   target="_blank">
																					<span
																						className="elementor-screen-only">Linkedin</span>
																					<i className="fa fa-linkedin"/>
																				</a>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															className="has_ae_slider elementor-element elementor-element-25e2a74 elementor-column elementor-col-20 elementor-inner-column"
															data-id="25e2a74" data-element_type="column">
															<div
																className="elementor-column-wrap elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div data-id="3f0abb6"
																		 className="elementor-element elementor-element-3f0abb6 elementor-align-justify elementor-widget elementor-widget-button"
																		 data-element_type="button.default">
																		<div className="elementor-widget-container">
																			<div
																				className="elementor-button-wrapper">
																				<a href="https://secure8.i-doxs.net/Middlesex/"
																				   className="elementor-button-link elementor-button elementor-size-sm"
																				   target="_blank" role="button">
																					<span
																						className="elementor-button-content-wrapper">
																						<span
																							className="elementor-button-text">MY H2O SMARTPAY</span>
																					</span>
																				</a>
																			</div>
																		</div>
																	</div>
																	<div data-id="680ca463"
																		 className="elementor-element elementor-element-680ca463 elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<h2 className="elementor-heading-title elementor-size-default">Important
																				Links
																			</h2></div>
																	</div>
																	<div data-id="711b365"
																		 className="elementor-element elementor-element-711b365 elementor-hidden-phone elementor-nav-menu--dropdown-mobile elementor-nav-menu--stretch elementor-nav-menu__text-align-center elementor-nav-menu--indicator-classic elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
																		 data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;full_width&quot;:&quot;stretch&quot;,&quot;toggle&quot;:&quot;burger&quot;}"
																		 data-element_type="nav-menu.default">
																		<div className="elementor-widget-container">
																			<nav
																				className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
																				<ul id="menu-1-711b365"
																					className="elementor-nav-menu sm-vertical"
																					data-smartmenus-id="15370504414298784">
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-672">
																						<a href="https://www.middlesexwater.com/water-quality/"
																						   className="elementor-item">Water
																							Quality Reports</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-642">
																						<a href="https://www.homeserveusa.com/mail/mwc"
																						   className="elementor-item">Emergency
																							Repair Plans</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-645">
																						<a href="https://waterfortomorrowmwc.com/"
																						   className="elementor-item">Water
																							for Tomorrow Program</a>
																					</li>
																				</ul>
																			</nav>
																			<div className="elementor-menu-toggle"
																			>
																				<i className="eicon"
																				   aria-hidden="true"/>
																			</div>
																			<nav
																				className="elementor-nav-menu--dropdown elementor-nav-menu__container"
																				style={{
																					top: '44px',
																					width: '1030px',
																					left: '0px'
																				}}>
																				<ul id="menu-2-711b365"
																					className="elementor-nav-menu sm-vertical"
																					data-smartmenus-id="15370504414299424">
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-672">
																						<a href="https://www.middlesexwater.com/water-quality/"
																						   className="elementor-item">Water
																							Quality Reports</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-642">
																						<a href="https://www.homeserveusa.com/mail/mwc"
																						   className="elementor-item">Emergency
																							Repair Plans</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-645">
																						<a href="https://waterfortomorrowmwc.com/"
																						   className="elementor-item">Water
																							for Tomorrow Program</a>
																					</li>
																				</ul>
																			</nav>
																		</div>
																	</div>
																	<div data-id="57bffd15"
																		 className="elementor-element elementor-element-57bffd15 elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<h2 className="elementor-heading-title elementor-size-default">
																				<a href="https://www.middlesexwater.com/news-room/">News
																					Room</a></h2></div>
																	</div>
																	<div data-id="c0ac6f6"
																		 className="elementor-element elementor-element-c0ac6f6 elementor-hidden-phone elementor-nav-menu--dropdown-mobile elementor-nav-menu--stretch elementor-nav-menu__text-align-center elementor-nav-menu--indicator-classic elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
																		 data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;full_width&quot;:&quot;stretch&quot;,&quot;toggle&quot;:&quot;burger&quot;}"
																		 data-element_type="nav-menu.default">
																		<div className="elementor-widget-container">
																			<nav
																				className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
																				<ul id="menu-1-c0ac6f6"
																					className="elementor-nav-menu sm-vertical"
																					data-smartmenus-id="15370504414305278">
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1972">
																						<a target="_blank"
																						   href="https://www.snl.com/IRW/CustomPage/4104374/Index?KeyGenPage=305595"
																						   className="elementor-item">Fact
																							Sheet</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1973">
																						<a target="_blank"
																						   href="http://investors.middlesexwater.com/News"
																						   className="elementor-item">Press
																							Releases</a></li>
																				</ul>
																			</nav>
																			<div className="elementor-menu-toggle"
																			>
																				<i className="eicon"
																				   aria-hidden="true"/>
																			</div>
																			<nav
																				className="elementor-nav-menu--dropdown elementor-nav-menu__container"
																				style={{
																					top: '44px',
																					width: '1030px',
																					left: '0px'
																				}}>
																				<ul id="menu-2-c0ac6f6"
																					className="elementor-nav-menu sm-vertical"
																					data-smartmenus-id="15370504414314648">
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1972">
																						<a target="_blank"
																						   href="https://www.snl.com/IRW/CustomPage/4104374/Index?KeyGenPage=305595"
																						   className="elementor-item">Fact
																							Sheet</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1973">
																						<a target="_blank"
																						   href="http://investors.middlesexwater.com/News"
																						   className="elementor-item">Press
																							Releases</a></li>
																				</ul>
																			</nav>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															className="has_ae_slider elementor-element elementor-element-a71382a elementor-column elementor-col-20 elementor-inner-column"
															data-id="a71382a" data-element_type="column">
															<div
																className="elementor-column-wrap elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div data-id="72cc717"
																		 className="elementor-element elementor-element-72cc717 elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<h2 className="elementor-heading-title elementor-size-default">
																				<a href="https://www.middlesexwater.com/about-us/">About
																					Us</a></h2></div>
																	</div>
																	<div data-id="4383f04"
																		 className="elementor-element elementor-element-4383f04 elementor-hidden-phone elementor-nav-menu--dropdown-mobile elementor-nav-menu--stretch elementor-nav-menu__text-align-center elementor-nav-menu--indicator-classic elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
																		 data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;full_width&quot;:&quot;stretch&quot;,&quot;toggle&quot;:&quot;burger&quot;}"
																		 data-element_type="nav-menu.default">
																		<div className="elementor-widget-container">
																			<nav
																				className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
																				<ul id="menu-1-4383f04"
																					className="elementor-nav-menu sm-vertical"
																					data-smartmenus-id="15370504414327358">
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1372">
																						<a href="https://www.middlesexwater.com/about-us/#corppro"
																						   className="elementor-item">Corporate
																							Profile</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1374">
																						<a href="https://www.middlesexwater.com/about-us/#services"
																						   className="elementor-item">Our
																							Services</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1376">
																						<a href="https://www.middlesexwater.com/about-us/#respons"
																						   className="elementor-item">Corporate
																							Responsibility</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1378">
																						<a href="https://www.middlesexwater.com/about-us/#team"
																						   className="elementor-item">Leadership
																							Team</a></li>
																				</ul>
																			</nav>
																			<div className="elementor-menu-toggle"
																			>
																				<i className="eicon"
																				   aria-hidden="true"/>
																			</div>
																			<nav
																				className="elementor-nav-menu--dropdown elementor-nav-menu__container"
																				style={{
																					top: '44px',
																					width: '1030px',
																					left: '0px'
																				}}>
																				<ul id="menu-2-4383f04"
																					className="elementor-nav-menu sm-vertical"
																					data-smartmenus-id="1537050441432026">
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1372">
																						<a href="https://www.middlesexwater.com/about-us/#corppro"
																						   className="elementor-item">Corporate
																							Profile</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1374">
																						<a href="https://www.middlesexwater.com/about-us/#services"
																						   className="elementor-item">Our
																							Services</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1376">
																						<a href="https://www.middlesexwater.com/about-us/#respons"
																						   className="elementor-item">Corporate
																							Responsibility</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1378">
																						<a href="https://www.middlesexwater.com/about-us/#team"
																						   className="elementor-item">Leadership
																							Team</a></li>
																				</ul>
																			</nav>
																		</div>
																	</div>
																	<div data-id="ce80cab"
																		 className="elementor-element elementor-element-ce80cab elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<h2 className="elementor-heading-title elementor-size-default">
																				<a href="https://www.middlesexwater.com/careers/">Careers &gt;</a>
																			</h2></div>
																	</div>
																	<div data-id="7df33b7"
																		 className="elementor-element elementor-element-7df33b7 elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<h2 className="elementor-heading-title elementor-size-default">
																				<a href="http://investors.middlesexwater.com/"
																				   target="_blank">Investors &gt;</a>
																			</h2></div>
																	</div>
																	<div data-id="d5034d6"
																		 className="elementor-element elementor-element-d5034d6 elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<h2 className="elementor-heading-title elementor-size-default">
																				<a href="https://www.middlesexwater.com/developers-partners/"
																				   target="_blank">Developers &amp; Partners &gt;</a>
																			</h2></div>
																	</div>
																</div>
															</div>
														</div>
														<div
															className="has_ae_slider elementor-element elementor-element-21f0e01 elementor-column elementor-col-20 elementor-inner-column"
															data-id="21f0e01" data-element_type="column">
															<div
																className="elementor-column-wrap elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div data-id="208149d9"
																		 className="elementor-element elementor-element-208149d9 elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<h2 className="elementor-heading-title elementor-size-default">
																				<a href="https://www.middlesexwater.com/customer-care/">Customer
																					Care</a></h2></div>
																	</div>
																	<div data-id="9a153f9"
																		 className="elementor-element elementor-element-9a153f9 elementor-hidden-phone elementor-nav-menu--dropdown-mobile elementor-nav-menu--stretch elementor-nav-menu__text-align-center elementor-nav-menu--indicator-classic elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
																		 data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;full_width&quot;:&quot;stretch&quot;,&quot;toggle&quot;:&quot;burger&quot;}"
																		 data-element_type="nav-menu.default">
																		<div className="elementor-widget-container">
																			<nav
																				className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
																				<ul id="menu-1-9a153f9"
																					className="elementor-nav-menu sm-vertical"
																					data-smartmenus-id="15370504414353088">
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-419">
																						<a href="https://www.middlesexwater.com/customer-care/payment-options/"
																						   className="elementor-item">Payment
																							Options</a></li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-364">
																						<a href="https://www.middlesexwater.com/customer-care/start-stop-or-transfer-service/"
																						   className="elementor-item">Start,
																							Stop or Transfer
																							Service</a></li>
																					<li className="vert-menu-child menu-item menu-item-type-custom menu-item-object-custom menu-item-1015">
																						<a href="https://www.middlesexwater.com/customer-care/start-stop-or-transfer-service/#change_address"
																						   className="elementor-item">Report
																							Change of Address</a>
																					</li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-295 current_page_item menu-item-361">
																						<a href="https://www.middlesexwater.com/customer-care/register-for-direct-alert/"
																						   className="elementor-item  elementor-item-active">Sign
																							Up for Direct Alert</a>
																					</li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-365">
																						<a href="https://www.middlesexwater.com/customer-care/customer-rights-responsibilities/"
																						   className="elementor-item">Customer
																							Rights &amp; Responsibilities</a>
																					</li>
																					<li className="vert-menu-child menu-item menu-item-type-post_type menu-item-object-page menu-item-362">
																						<a href="https://www.middlesexwater.com/customer-care/customer-rights-responsibilities/frequently-asked-questions-faqs/"
																						   className="elementor-item">Frequently
																							Asked Questions
																							(FAQs)</a></li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-359">
																						<a href="https://www.middlesexwater.com/customer-care/customer-communications/"
																						   className="elementor-item">Customer
																							Communications</a></li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-363">
																						<a href="https://www.middlesexwater.com/customer-care/rate-information/"
																						   className="elementor-item">Rate
																							Information</a></li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-358">
																						<a href="https://www.middlesexwater.com/customer-care/regulatory-filings/"
																						   className="elementor-item">Regulatory
																							Filings</a></li>
																				</ul>
																			</nav>
																			<div className="elementor-menu-toggle"
																			>
																				<i className="eicon"
																				   aria-hidden="true"/>
																			</div>
																			<nav
																				className="elementor-nav-menu--dropdown elementor-nav-menu__container"
																				style={{
																					top: '44px',
																					width: '1030px',
																					left: '0px'
																				}}>
																				<ul id="menu-2-9a153f9"
																					className="elementor-nav-menu sm-vertical"
																					data-smartmenus-id="15370504414359587">
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-419">
																						<a href="https://www.middlesexwater.com/customer-care/payment-options/"
																						   className="elementor-item">Payment
																							Options</a></li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-364">
																						<a href="https://www.middlesexwater.com/customer-care/start-stop-or-transfer-service/"
																						   className="elementor-item">Start,
																							Stop or Transfer
																							Service</a></li>
																					<li className="vert-menu-child menu-item menu-item-type-custom menu-item-object-custom menu-item-1015">
																						<a href="https://www.middlesexwater.com/customer-care/start-stop-or-transfer-service/#change_address"
																						   className="elementor-item">Report
																							Change of Address</a>
																					</li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-295 current_page_item menu-item-361">
																						<a href="https://www.middlesexwater.com/customer-care/register-for-direct-alert/"
																						   className="elementor-item  elementor-item-active">Sign
																							Up for Direct Alert</a>
																					</li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-365">
																						<a href="https://www.middlesexwater.com/customer-care/customer-rights-responsibilities/"
																						   className="elementor-item">Customer
																							Rights &amp; Responsibilities</a>
																					</li>
																					<li className="vert-menu-child menu-item menu-item-type-post_type menu-item-object-page menu-item-362">
																						<a href="https://www.middlesexwater.com/customer-care/customer-rights-responsibilities/frequently-asked-questions-faqs/"
																						   className="elementor-item">Frequently
																							Asked Questions
																							(FAQs)</a></li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-359">
																						<a href="https://www.middlesexwater.com/customer-care/customer-communications/"
																						   className="elementor-item">Customer
																							Communications</a></li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-363">
																						<a href="https://www.middlesexwater.com/customer-care/rate-information/"
																						   className="elementor-item">Rate
																							Information</a></li>
																					<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-358">
																						<a href="https://www.middlesexwater.com/customer-care/regulatory-filings/"
																						   className="elementor-item">Regulatory
																							Filings</a></li>
																				</ul>
																			</nav>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															className="has_ae_slider elementor-element elementor-element-5d28482 elementor-column elementor-col-20 elementor-inner-column"
															data-id="5d28482" data-element_type="column">
															<div
																className="elementor-column-wrap elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div data-id="1fdb11f"
																		 className="elementor-element elementor-element-1fdb11f elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<h2 className="elementor-heading-title elementor-size-default">
																				<a href="https://www.middlesexwater.com/tips-tools/">Tips &amp; Tools</a>
																			</h2></div>
																	</div>
																	<div data-id="812cf29"
																		 className="elementor-element elementor-element-812cf29 elementor-hidden-phone elementor-nav-menu--dropdown-mobile elementor-nav-menu--stretch elementor-nav-menu__text-align-center elementor-nav-menu--indicator-classic elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
																		 data-settings="{&quot;layout&quot;:&quot;vertical&quot;,&quot;full_width&quot;:&quot;stretch&quot;,&quot;toggle&quot;:&quot;burger&quot;}"
																		 data-element_type="nav-menu.default">
																		<div className="elementor-widget-container">
																			<nav
																				className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
																				<ul id="menu-1-812cf29"
																					className="elementor-nav-menu sm-vertical"
																					data-smartmenus-id="1537050441437807">
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1946">
																						<a href="https://www.middlesexwater.com/tips-tools/"
																						   className="elementor-item">Use
																							Water Wisely</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1949">
																						<a href="https://www.middlesexwater.com/tips-tools/#tip_emergency"
																						   className="elementor-item">Prepare
																							for Water
																							Emergencies</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1951">
																						<a href="https://www.middlesexwater.com/tips-tools/#tip_lead"
																						   className="elementor-item">Get
																							the Lead Out</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1950">
																						<a href="https://www.middlesexwater.com/tips-tools/#tip_call-dig"
																						   className="elementor-item">Call
																							Before You Dig!</a></li>
																				</ul>
																			</nav>
																			<div className="elementor-menu-toggle"
																			>
																				<i className="eicon"
																				   aria-hidden="true"/>
																			</div>
																			<nav
																				className="elementor-nav-menu--dropdown elementor-nav-menu__container"
																				style={{
																					top: '44px',
																					width: '1030px',
																					left: '0px'
																				}}>
																				<ul id="menu-2-812cf29" className="elementor-nav-menu sm-vertical" data-smartmenus-id="15370504414375195">
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1946">
																						<a href="https://www.middlesexwater.com/tips-tools/"
																						   className="elementor-item">Use Water Wisely</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1949">
																						<a href="https://www.middlesexwater.com/tips-tools/#tip_emergency"
																						   className="elementor-item">Prepare for Water Emergencies</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1951">
																						<a href="https://www.middlesexwater.com/tips-tools/#tip_lead"
																						   className="elementor-item">Get the Lead Out</a></li>
																					<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1950">
																						<a href="https://www.middlesexwater.com/tips-tools/#tip_call-dig"
																						   className="elementor-item">Call Before You Dig!</a></li>
																				</ul>
																			</nav>
																		</div>
																	</div>
																	<div data-id="a4fa25a"
																		 className="elementor-element elementor-element-a4fa25a elementor-headline--style-highlight elementor-widget elementor-widget-animated-headline"
																		 data-settings="{&quot;marker&quot;:&quot;underline&quot;,&quot;highlighted_text&quot;:&quot;Newsletter&quot;,&quot;headline_style&quot;:&quot;highlight&quot;}"
																		 data-element_type="animated-headline.default">
																		<div className="elementor-widget-container">
																			<h2 className="elementor-headline">
																				<span
																					className="elementor-headline-plain-text elementor-headline-text-wrapper">Subscribe to our</span>
																				<span
																					className="elementor-headline-dynamic-wrapper elementor-headline-text-wrapper"><span
																					className="elementor-headline-dynamic-text elementor-headline-text-active">Newsletter</span><svg
																					xmlns="http://www.w3.org/2000/svg"
																					viewBox="0 0 500 150"
																					preserveAspectRatio="none"><path
																					d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"/></svg></span>
																			</h2>
																		</div>
																	</div>
																	<div data-id="1fe2c7f"
																		 className="elementor-element elementor-element-1fe2c7f elementor-button-align-stretch elementor-widget elementor-widget-form"
																		 data-element_type="form.default">
																		<div className="elementor-widget-container">
																			<form className="elementor-form" method="post" name="Newsletter Subscribe">
																				<input type="hidden" name="post_id" value="137"/>
																				<input type="hidden" name="form_id" value="1fe2c7f"/>
																				<div
																					className="elementor-form-fields-wrapper elementor-labels-">
																					<div
																						className="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100 elementor-field-required">
																						<label
																							htmlFor="form-field-name"
																							className="elementor-field-label elementor-screen-only">Name</label><input
																						size={1} type="text"
																						name="form_fields[name]"
																						id="form-field-name"
																						className="elementor-field elementor-size-xs  elementor-field-textual"
																						placeholder="Name"
																						required={true}
																						aria-required="true"/>
																					</div>
																					<div
																						className="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-email elementor-col-100">
																						<label
																							htmlFor="form-field-email"
																							className="elementor-field-label elementor-screen-only">Email</label><input
																						size={1}
																						type="email"
																						name="form_fields[email]"
																						id="form-field-email"
																						className="elementor-field elementor-size-xs  elementor-field-textual"
																						placeholder="Email"/>
																					</div>
																					<div className="elementor-field-group elementor-column elementor-field-type-submit elementor-col-100">
																						<button
																							type="submit"
																							className="elementor-button elementor-size-sm">
																							<span>
																								<span
																									className="elementor-button-text">Send</span>
																							</span>
																						</button>
																					</div>
																				</div>
																			</form>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<section
												className="has_ae_slider elementor-element elementor-element-eb62770 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
												data-id="eb62770" data-element_type="section">
												<div className="elementor-container elementor-column-gap-default">
													<div className="elementor-row">
														<div
															className="has_ae_slider elementor-element elementor-element-18dfb7c elementor-column elementor-col-50 elementor-inner-column"
															data-id="18dfb7c" data-element_type="column">
															<div
																className="elementor-column-wrap elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div data-id="3cee0c1"
																		 className="elementor-element elementor-element-3cee0c1 elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<p className="elementor-heading-title elementor-size-default">
																				<a href="https://www.middlesexwater.com/terms-of-use"
																				   target="_blank">Terms of Use</a>
																			</p></div>
																	</div>
																</div>
															</div>
														</div>
														<div
															className="has_ae_slider elementor-element elementor-element-f7f8a54 elementor-column elementor-col-50 elementor-inner-column"
															data-id="f7f8a54" data-element_type="column">
															<div
																className="elementor-column-wrap elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div data-id="237f1d5"
																		 className="elementor-element elementor-element-237f1d5 elementor-widget elementor-widget-heading"
																		 data-element_type="heading.default">
																		<div className="elementor-widget-container">
																			<p className="elementor-heading-title elementor-size-default">
																				<a href="https://www.middlesexwater.com/privacy-policy">Privacy
																					Policy</a></p></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<div data-id="f16dfdc"
												 className="elementor-element elementor-element-f16dfdc elementor-widget elementor-widget-text-editor"
												 data-element_type="text-editor.default">
												<div className="elementor-widget-container">
													<div className="elementor-text-editor elementor-clearfix"><p>©
														2018 Middlesex Water Company, Inc.&nbsp; All rights
														reserved.</p></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
		<div dangerouslySetInnerHTML={{__html: `
        <script type='text/javascript'>
/* <![CDATA[ */
var aepro = {"ajaxurl":"https:\\/\\/www.middlesexwater.com\\/wp-admin\\/admin-ajax.php"};
/* ]]> */
</script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/anywhere-elementor-pro/includes/assets/js/ae-pro.min.js?ver=2.10.1'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var aepro_editor = {"plugin_url":"https:\\/\\/www.middlesexwater.com\\/wp-content\\/plugins\\/anywhere-elementor-pro"};
/* ]]> */
</script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/anywhere-elementor-pro/includes/assets/js/common.min.js?ver=2.10.1'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/anywhere-elementor-pro/includes/assets/lib/vegas/vegas.min.js?ver=2.4.0'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/anywhere-elementor-pro/includes/assets/lib/masonry/js/masonry.pkgd.min.js?ver=2.0.1'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/essential-addons-for-elementor-lite/assets/js/eael-scripts.js?ver=1.0'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/essential-addons-for-elementor-lite/assets/js/tooltipster.bundle.min.js?ver=1.0'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var smooth = {"elements":[".smooth-scroll","li.smooth-scroll a"],"duration":"800"};
/* ]]> */
</script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/gp-premium/general/js/smooth-scroll.min.js?ver=1.7.2'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var sticky_anything_engage = {"element":"#sidebar-menu","topspace":"0","minscreenwidth":"0","maxscreenwidth":"999999","zindex":"1","legacymode":"","dynamicmode":"","debugmode":"","pushup":"div.site-footer","adminbar":"1"};
/* ]]> */
</script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/sticky-menu-or-anything-on-scroll/assets/js/stickThis.js?ver=2.1.1'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var sta_settings = {"distance":"0","speed":"1000","exceptions":""};
/* ]]> */
</script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/scroll-to-anchor/includes/../js/scroll-to-anchor.min.js?ver=4.9.8'></script>
<!--[if lte IE 11]>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/themes/generatepress/js/classList.min.js?ver=2.1.4'></script>
<![endif]-->
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/themes/generatepress/js/menu.min.js?ver=2.1.4'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/themes/generatepress/js/a11y.min.js?ver=2.1.4'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/themes/generatepress/js/back-to-top.min.js?ver=2.1.4'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-includes/js/wp-embed.min.js?ver=4.9.8'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/elementor-pro/assets/lib/smartmenus/jquery.smartmenus.min.js?ver=1.0.1'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/elementor-pro/assets/lib/sticky/jquery.sticky.min.js?ver=2.1.8'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var ElementorProFrontendConfig = {"ajaxurl":"https:\\/\\/www.middlesexwater.com\\/wp-admin\\/admin-ajax.php","nonce":"79dd4bbc7c","shareButtonsNetworks":{"facebook":{"title":"Facebook","has_counter":true},"twitter":{"title":"Twitter"},"google":{"title":"Google+","has_counter":true},"linkedin":{"title":"LinkedIn","has_counter":true},"pinterest":{"title":"Pinterest","has_counter":true},"reddit":{"title":"Reddit","has_counter":true},"vk":{"title":"VK","has_counter":true},"odnoklassniki":{"title":"OK","has_counter":true},"tumblr":{"title":"Tumblr"},"delicious":{"title":"Delicious"},"digg":{"title":"Digg"},"skype":{"title":"Skype"},"stumbleupon":{"title":"StumbleUpon","has_counter":true},"telegram":{"title":"Telegram"},"pocket":{"title":"Pocket","has_counter":true},"xing":{"title":"XING","has_counter":true},"whatsapp":{"title":"WhatsApp"},"email":{"title":"Email"},"print":{"title":"Print"}},"facebook_sdk":{"lang":"en_US","app_id":""}};
/* ]]> */
</script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/elementor-pro/assets/js/frontend.min.js?ver=2.1.8'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-includes/js/jquery/ui/position.min.js?ver=1.11.4'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/elementor/assets/lib/dialog/dialog.min.js?ver=4.5.0'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/elementor/assets/lib/waypoints/waypoints.min.js?ver=4.0.2'></script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/elementor/assets/lib/swiper/swiper.jquery.min.js?ver=4.4.3'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var elementorFrontendConfig = {"isEditMode":"","is_rtl":"","breakpoints":{"xs":0,"sm":480,"md":768,"lg":1025,"xl":1440,"xxl":1600},"urls":{"assets":"https:\\/\\/www.middlesexwater.com\\/wp-content\\/plugins\\/elementor\\/assets\\/"},"settings":{"page":[],"general":{"elementor_global_image_lightbox":"yes","elementor_enable_lightbox_in_editor":"yes"}},"post":{"id":295,"title":"Sign Up for Direct Alert","excerpt":""}};
/* ]]> */
</script>
<script type='text/javascript' src='https://www.middlesexwater.com/wp-content/plugins/elementor/assets/js/frontend.min.js?ver=2.2.1'></script>
	<script>
		jQuery(document).ready(function() {
			jQuery( '.elementor-accordion .elementor-tab-title' ).removeClass( 'elementor-active' );
			jQuery( '.elementor-accordion .elementor-tab-content' ).css( 'display', 'none' );
		});
	</script>
`}}/>
	</div>
);